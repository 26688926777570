class NavigationLoader {
    constructor() {
        this.initializeNavigation();
    }

    async initializeNavigation() {
        this.container = document.querySelector("#nav-container");
        const html = await this.fetchNavigationHtml();

        if (html) {
            this.container.innerHTML = html;
        }
    }

    async fetchNavigationHtml() {
        // Get props
        const documentId = this.container.getAttribute(
            "data-navigaton-prop-document"
        );
        const objectId = this.container.getAttribute(
            "data-navigaton-prop-object"
        );

        // Build navigation params
        const params = [];

        if (documentId) {
            params.push("document=" + documentId);
        }

        if (objectId) {
            params.push("object=" + objectId);
        }

        // Build endpoint
        let endpoint =
            `/api/retrotimber-corporate/${this.getLocale()}/navigation?` +
            params.join("&");

        // Fetch navigation
        const response = await fetch(endpoint);

        if (!response.ok) {
            console.log("Error while fetching navigation: response.ok = false");
            return false;
        }

        const json = await response.json();

        if (!json.success) {
            console.log(
                "Error while fetching navigation: json.success = false"
            );
            return false;
        }

        return json.html;
    }

    getLocale() {
        return document.documentElement.lang;
    }
}

new NavigationLoader();
